// TrackingContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TrackingContextProps {
  tracking: {
    contentId: string;
    duration: number;
  };
  setTracking: React.Dispatch<React.SetStateAction<{
    contentId: string;
    duration: number;
  }>>;
}

const TrackingContext = createContext<TrackingContextProps | undefined>(undefined);

interface TrackingProviderProps {
  children: ReactNode;
}

export const TrackingProvider: React.FC<TrackingProviderProps> = ({ children }) => {
  const [tracking, setTracking] = useState({
    contentId: '',
    duration: 0,
  });

  return (
    <TrackingContext.Provider value={{ tracking, setTracking }}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};
