import React, { Suspense } from "react";
import { UnProtectRoutes } from "../hooks/protectedRoutes/unProtectedRoutes";

// Lazy load route components
const Layout = React.lazy(() => import("../layout/auth/Layout"));
const ChangePassword = React.lazy(() => import("../pages/auth/ChangePassword"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const Register = React.lazy(() => import("../pages/frontend/auth/Register"));
const CreatePassword = React.lazy(() => import("../pages/frontend/auth/create-password"));




export const AuthRoutes = {
  element: <UnProtectRoutes />,
  children: [
    {
      path: "/auth",
      element: <Layout />,
      children: [
        {
          path: "login",
          element: <Login title="Login" />,
        },
        {
          path: "forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "forget-password/:token",
          element: <ChangePassword />,
        },
        {
          path: "registration/:token",
          element: <CreatePassword />,
        },
      ],
    },
    {
      path: "/parents/auth",
      element: <Layout />,
      children: [
        {
          path: "signup",
          element: <Register />,
        },
        {
          path: "login",
          element: <Login title="Parent Login" />,
        },
        {
          path: "registration/:token",
          element: <CreatePassword />,
        },
      ],
    },
  ],
};
