import { createContext, ReactNode, useContext, useReducer } from "react"
import { api } from "../../config/apis";

type AuthStateType = {
    user: any;
    access_token: any;
    userRole: any;
    userRolePermissions: any;
    status:any
}

type AuthContextType = {
    state?: AuthStateType|null,
    dispatch?: any,    
}

export const AuthContext = createContext<AuthContextType>({
    state: null,
    dispatch: null
})

interface AuthProps {
    children: ReactNode;
}

export const authReducer = function(state: any, action: any) {        
    switch(action.type) {
        case 'LOGIN':
            api.defaults.headers.common['Authorization'] = `Bearer ${action.payload.access_token}`;            
            return {state: action.payload}
        case 'LOGOUT':            
            return {state: null}
        default:
            return state
        
    }
}

export const AuthProvider = function(prop: AuthProps) {
    const initialState: string|null = localStorage.getItem('auth')

    const [state, dispatch] = useReducer(authReducer, {
        state: initialState ? JSON.parse(initialState) : null,
    });
    if(initialState) api.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(initialState).access_token}`;    

    return <AuthContext.Provider value={{...state, dispatch}}>
        {prop.children}
    </AuthContext.Provider>
}

export const useAuth = function() {    
    return useContext(AuthContext);
}