// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { FIREBASE_APIKEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_MEASUREMENT_ID, FIREBASE_MESSAGING_ID, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_VAPID_ID } from "../config/constants/EnviormentVariables";
import { getMessaging, getToken, onMessage} from 'firebase/messaging';
import { AdminApi, api, resolveUrl } from "../config/apis";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FIREBASE_APIKEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseDB = getFirestore(firebaseApp);

const messaging = getMessaging();

export const requestForToken = async (userID: string) => {
  return getToken(messaging, { vapidKey: FIREBASE_VAPID_ID })
    .then((currentToken) => {
      if (currentToken && userID) {

        const payload = {
          token: currentToken,
          deviceType: 'web',
        };
        api
      .post(resolveUrl(AdminApi.REGISTER_FCM_TOKEN, userID), payload)
        .then((res) => {
          console.log(res.data);
        })
      } else {
        
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {      
      resolve(payload);
    });
});
