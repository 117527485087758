
import React from 'react';
import styles from "./footer.module.scss";

interface Props {
  text: string;
}

const FooterText: React.FC<Props> = ({ text }) => {
  return (
    <div className={`${styles.footer_text} footer_text`}>
    {/* // <div className='footer_text'> */}
      <p>{text}</p>
    </div>
  );
};

export default FooterText;

