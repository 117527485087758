export const getMenuItems = (state: { userRole: string }) => {
  const menuItems = [
    {
      label: "Dashboard",
      path: "/admin",
      permission: "NR",
      icon: "dashboard-icon",
      className: "",
    },
    {
      label: "Users",
      permission: "view.manage-users",
      //   notForAdmin: true,
      icon: "users-icon",
      className: "has-child",
      subMenu: [
        {
          label: "Students",
          path: "/admin/user/student",
          permission: "view.manage-users",
        },
        {
          label: "Parents",
          path: "/admin/user/parents",
          permission: "view.manage-users",
        },
        {
          label: "Teachers",
          path: "/admin/user/teachers",
          permission: "view.manage-users",
        },
      ],
    },
    {
      label: "CMS",
      permission: "view.manage-cms",
      icon: "cms-icon",
      className: "has-child",
      subMenu: [
        {
          label: "Home",
          path: "/admin/cms/home",
          permission: "view.manage-cms",
        },
        {
          label: "About",
          path: "/admin/cms/about",
          permission: "view.manage-cms",
        },
        {
          label: "FAQ'S",
          path: "/admin/cms/faqs",
          permission: "view.manage-cms",
        },
        {
          label: "Contact Us",
          path: "/admin/cms/contact-us",
          permission: "view.manage-cms",
        },
        {
          label: "Super Curriculum",
          path: "/admin/cms/the-super-curriculum",
          permission: "view.manage-cms",
        },
        {
          label: "IGCSE",
          path: "/admin/cms/IGCSE",
          permission: "view.manage-cms",
        },
        {
          label: "Secondary-KS3",
          path: "/admin/cms/Secondary-KS3",
          permission: "view.manage-cms",
        },
        // {
        //   label: "Terms & Conditions",
        //   path: "/admin/cms/termsandconditions",
        //   permission: "view.manage-cms",
        // },
        {
          label: "Emails",
          path: "/admin/cms/emails",
          permission: "view.manage-cms",
        },
        {
          label: "Privacy Policy",
          path: "/admin/cms/privacy-policy",
          permission: "view.manage-cms",
        },
        {
          label: "Terms & Conditions",
          path: "/admin/cms/terms-conditions",
          permission: "view.manage-cms",
        },
        {
          label: "Footer",
          path: "/admin/cms/footer",
          permission: "view.manage-cms",
        },
      ],
    },
    {
      label: "Students",
      path: "/admin/student",
      notForAdmin: true,
      permission: "view.manage-kids",
      icon: "users-icon-stu",
      className: "",
    },
    {
      label: "Subjects",
      path: "/admin/courses",
      permission: "view.manage-courses",
      icon: "courses-icon",
      className: "",
    },
    {
      label: "Assignments",
      path: "/admin/students/assignments",
      notForAdmin: true,
      permission: "view.manage-quizes",
      icon: "assigment-icon",
      className: "",
    },
    {
      label: "Quizzes",
      path: "/admin/quiz",
      permission: "view.manage-quizes",
      icon: "quizzes-icon",
      className: "",
    },
    {
      label: "Forums",
      path: "/admin/forum",
      permission: "view.manage-forums",
      icon: "forums-icon",
      className: "",
    },
    {
      label: "Transactions",
      path: "/admin/transactions",
      notForParent: true,
      permission: "view.manage-transactions",
      icon: "transactions-icon",
      className: "",
    },
    {
      label: "Finances",
      permission: "view.manage-finances",
      icon: "finances-icon",
      notForAdmin: true,
      className: "has-child",
      subMenu: [
        {
          label: "Upcoming Instalment",
          path: "/admin/upcoming-installments",
          permission: "view.manage-transactions",
          // icon: "transactions-icon",
          className: "",
        },
        {
          label: "Transaction History",
          path: "/admin/transactions",
          permission: "view.manage-transactions",
          // icon: "transactions-icon",
          className: "",
        },
      ],
    },
    {
      label: "Profile",
      path: "/admin/profile/student",
      icon: "profile-icon",
      notForAdmin: true,
      permission: "view.manage-student-profile",
    },
    {
      label: "Parent Settings",
      path: "/admin/profile/parent",
      icon: "settings-icon",
      notForAdmin: true,
      permission: "view.manage-parent-profile",
    },
    {
      label: "Teacher Settings",
      path: "/admin/profile/teacher",
      icon: "settings-icon",
      notForAdmin: true,
      permission: "view.manage-teacher-profile",
    },
    {
      label: "Enrolled Students",
      path: "/admin/student/subjects",
      notForAdmin: true,
      permission: "view.manage-my-students-subjects",
      icon: "enrolled-students-icon",
      className: "",
    },
    {
      label: "Subject Catalog",
      path: "/admin/course-catalog",
      notForAdmin: true,
      permission: "view.manage-course-catalog",
      icon: "subject-catalog-icon",
      className: "",
    },
    {
      label: "Subjects",
      path: "/admin/student-courses",
      notForAdmin: true,
      permission: "view.manage-student-courses",
      icon: "courses-icon",
      className: "",
    },
    {
      label: "Assignments",
      path: "/admin/student-assignments",
      notForAdmin: true,
      permission: "view.manage-student-courses",
      icon: "assigment-icon",
      className: "",
    },
    {
      label: "Admin Support",
      path: "/admin/support",
      permission: "view.manage-admin-support",
      icon: "Admin-icon",
      className: "",
    },
    {
      label: "Complaints",
      path: "/admin/complaints",
      permission: "view.manage-complaints",
      icon: "complaints-icon",
      className: "",
    },
    {
      label: "Messaging",
      path: "/admin/messages",
      notForAdmin: true,
      permission: "view.manage-messages",
      icon: "messaging-icon",
      className: "",
    },
    {
      label: "Question Bank",
      path: "/admin/question-bank",
      permission: "view.manage-question-bank",
      icon: "questionsbank-icon",
      className: "",
    },
    {
      label: "My Subjects",
      path: "/admin/teacher-courses",
      notForAdmin: true,
      permission: "view.manage-teacher-courses",
      icon: "courses-icon",
      className: "",
    },
    {
      label: "Notice Board",
      path: "/admin/notice-board",
      permission: "view.manage-notice-board",
      icon: "noticeboard-icon",
      className: "",
    },
    {
      label: "Subjects",
      path: "/admin/purchased-courses",
      notForAdmin: true,
      permission: "view.manage-parent-courses",
      icon: "courses-icon",
      className: "",
    },
    {
      label: "Subjects Access",
      path: "/admin/subject-access",
      permission: "view.manage-assign-and-revoke",
      icon: "access-icon",
      className: "",
    },
    {
      label: "Progress",
      path: "/admin/student/progress-report",
      notForAdmin: true,
      permission: "view.manage-student-profile",
      icon: "progress-icon",
      className: "",
    },
    {
      label: "Settings",
      path: "/admin/setting",
      permission: "view.manage-settings",
      icon: "settings-icon",
      className: "",
    },
  ];

  interface CustomOrders {
    [role: string]: string[];
  }

  const customOrders: CustomOrders = {
    "Super Admin": [
      "Dashboard",
      "Users",
      "Students",
      "CMS",
      "Subjects",
      "Assignments",
      "Quizzes",
      "Forums",
      "Transactions",
      "Profile",
      "Parent Settings",
      "Teacher Settings",
      "Enrolled Students",
      "Subject Catalog",
      "Subjects",
      "Assignments",
      "Admin Support",
      "Complaints",
      "Messaging",
      "Question Bank",
      "My Subjects",
      "Notice Board",
      "Subjects",
      "Subjects Access",
      "Progress",
      "Settings",
    ],

    Teacher: [
      "Dashboard",
      "Users",
      "Students",
      "CMS",
      "Subjects",
      "Assignments",
      "Quizzes",
      "Forums",
      "Transactions",
      "Profile",
      "Parent Settings",
      "Teacher Settings",
      "Enrolled Students",
      "Subject Catalog",
      "Subjects",
      "Assignments",
      "Admin Support",
      "Complaints",
      "Messaging",
      "Question Bank",
      "My Subjects",
      "Notice Board",
      "Subjects",
      "Subjects Access",
      "Progress",
      "Settings",
    ],
    Student: [
      "Dashboard",
      "Users",
      "Students",
      "CMS",
      "Subjects",
      "Assignments",
      "Quizzes",
      "Forums",
      "Transactions",
      "Profile",
      "Parent Settings",
      "Teacher Settings",
      "Enrolled Students",
      "Subject Catalog",
      "Subjects",
      "Assignments",
      "Admin Support",
      "Complaints",
      "Messaging",
      "Question Bank",
      "My Subjects",
      "Notice Board",
      "Subjects",
      "Subjects Access",
      "Progress",
      "Settings",
    ],
    Parent: [
      "Dashboard",
      "Users",
      "Students",
      "CMS",
      "Subjects",
      "Finances",
      "Assignments",
      "Quizzes",
      "Forums",
      "Transactions",
      "Profile",
      "Parent Settings",
      "Teacher Settings",
      "Enrolled Students",
      "Subject Catalog",
      "Subjects",
      "Assignments",
      "Admin Support",
      "Complaints",
      "Messaging",
      "Question Bank",
      "My Subjects",
      "Notice Board",
      "Subjects",
      "Subjects Access",
      "Progress",
      "Settings",
    ],
  };

  let customOrder: string[] = [];

  // Check if the user role includes "admin" in lowercase
  if (state.userRole && state.userRole.toLowerCase().includes("admin")) {
    customOrder = customOrders["Super Admin"] || [];
  } else {
    customOrder = customOrders[state.userRole] || [];
  }

  // Sort the menu items based on custom order
  menuItems.sort((a, b) => {
    const indexA = customOrder.indexOf(a.label);
    const indexB = customOrder.indexOf(b.label);
    return indexA - indexB;
  });

  return menuItems;
};
