import React from "react";
import FooterLinks from "./FooterLinks";
import FooterText from "./FooterText";
import styles from "./footer.module.scss";
import Box from "@mui/material/Box";

const currentYear = new Date().getFullYear();

const Footer: React.FC = () => {
  return (
    <footer className={`${styles.footer} footer`}>
      {/* <footer className='footer'> */}
      <FooterText
        text={`Copyright © ${currentYear} Edexonline. All Rights Reserved.`}
      />
      <FooterLinks />
    </footer>
  );
};

export default Footer;
