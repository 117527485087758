import axios from 'axios';
import { API_KEY } from '../constants/EnviormentVariables';

export * from './admin';
export * from './auth';
export * from './frontend';

export const api = axios.create({
    baseURL: API_KEY,
    headers: {
        'Content-Type': 'application/json'
    }
    
});

export const resolveUrl = (route: string, param = ''): string => {
    let url: string = route
    const paramRequired: boolean = url.includes(":")
    const paramOptional: boolean = url.includes("?")
    let paramName = url.substr(url.lastIndexOf(":") + 1)
    
    if (paramRequired && !paramOptional && !param)
        throw new Error(`parameter ${paramName} is required`)

    if (paramName)
        url = url.replace(`:${paramName}`, param)

    return url;
}