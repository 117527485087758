import { useState, useEffect } from "react";
import { getCartData } from "../../../helper/helperFunction";
import { useAuth } from "../../../hooks/auth/auth";

interface CartData {
  subTotalPrice: number;
  totalItemCount: number;
  totalDiscount: number;
  totalPrice: number;
  courseId: number[];
  cartId?: number;
  discountId?: number;
  myTest?: string;
  SectionForInstallment?: number | null;
  isInstallmentAllowed?: boolean;
  message?: string;
}

export const useCartData = (): CartData & { updateCartData: () => void } => {
  const Parent = useAuth();

  const cardId = Parent.state?.user.id;
  const userRoll = Parent.state?.userRole === "Parent";

  const [event, setEvent] = useState<number>(0); // State variable to trigger the reload
  const [cartData, setCartData] = useState<CartData>({
    subTotalPrice: 0,
    totalItemCount: 0,
    totalDiscount: 0,
    totalPrice: 0,
    courseId: [],
    SectionForInstallment: null,
    isInstallmentAllowed: true,
    message: "",
  });

  const {
    subTotalPrice,
    totalItemCount,
    totalDiscount,
    totalPrice,
    courseId,
    cartId,
    discountId,
    message,
    SectionForInstallment,
    isInstallmentAllowed,
  } = cartData;

  useEffect(() => {
    async function fetchCartData() {
      try {
        const data = await getCartData(cardId);

        const courseIds = data.cartItems.map(
          (item: any) => item.cartItem.courses.id
        );
        const cartItemPrices = data.cartItems.map((item: any) =>
          Number(item.cartItem.courses.price)
        );
        const subTotal = cartItemPrices.reduce(
          (acc: number, price: number) => acc + price,
          0
        );
        const disc = data.totalItems.discountedPrice;
        const fullDiscount = data.totalItems.full_discount;
        const SectionForInstallment = data.SectionForInstallment;
        const isInstallmentAllowed = data.isInstallmentAllowed;
        const message = data.message;
        let sub_Total: number;
        let total_Discount: number;

        if (fullDiscount) {
          sub_Total = 0;
          total_Discount = subTotal;
        } else {
          sub_Total = subTotal - (disc > 0 ? subTotal - disc : 0);
          total_Discount = disc > 0 ? subTotal - disc : 0;
        }

        setCartData((prevState) => ({
          ...prevState,
          courseId: courseIds,
          cartId: data.totalItems.cartId,
          subTotalPrice: subTotal,
          totalDiscount: total_Discount,
          totalItemCount: data.totalItems.totalQuantity,
          totalPrice: sub_Total,
          discountId: data.totalItems.discountId,
          SectionForInstallment: SectionForInstallment,
          isInstallmentAllowed: isInstallmentAllowed,
          message: message,
        }));
      } catch (error) {
        console.error(error);
      }
    }

    if (cardId && userRoll) {
      fetchCartData();
    }
  }, [cardId, event]);

  const updateCartData = () => {
    setEvent((prevEvent) => prevEvent + 1);
  };

  return {
    subTotalPrice,
    totalItemCount,
    totalDiscount,
    totalPrice,
    courseId,
    cartId,
    discountId,
    updateCartData,
    message,
    SectionForInstallment,
    isInstallmentAllowed,
  };
};
