import React, { ReactNode, createContext, useContext } from "react";
import { useCartData } from "../pages/admin/manage-purchase-courses/useCartData";

interface CartContextType {
  subTotalPrice: number;
  updateCartData: () => void;
  totalItemCount: number;
  totalDiscount: number;
  totalPrice: number;
  cartId?: number;
  courseId: number[];
  discountId?: number;
  SectionForInstallment?: number | null;
  isInstallmentAllowed?: boolean;
  message?: string;
}

interface CartProviderProps {
  children: ReactNode;
}

export const CartContext = createContext<CartContextType | undefined>(
  undefined
);

export const useCartContext = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }
  return context;
};

export const CartProvider = function (props: CartProviderProps) {
  const cartData = useCartData();

  return (
    <CartContext.Provider value={cartData}>
      {props.children}
    </CartContext.Provider>
  );
};
