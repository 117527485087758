/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAuth } from "../../../hooks/auth/auth";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  activityLog,
  GetUserName,
  GetUserProfileImage,
  GetUserRole,
} from "../../../helper/helperFunction";
import { Autocomplete, Stack, SvgIcon, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import cartIcon from "../../../static/images/cart-icon.svg";
import { useCartContext } from "../../../context/CartContext";
import { AdminApi, api, resolveUrl } from "../../../config/apis";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

const pages = ["Products", "Pricing", "Blog"];

// const settings = ['Logout'];
interface Course {
  id: number;
  name: string;
}
interface Student {
  id: number;
  Name: string;
}

function Header() {
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const auth = useAuth();
  const userRole = auth.state?.userRole;
  const isParentUser = userRole === "Parent";

  const logout = function () {
    dispatch({ type: "LOGOUT" });
    activityLog();
    localStorage.removeItem("auth");
    Cookies.remove("addStudentPopup");
    Cookies.remove("popupSecondShown");
  };

  const { REACT_APP_SERVER_BASE } = process.env;

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchCourse, setSearchCourse] = useState<Course[]>([]);
  const [searchStudent, setSearchStudent] = useState<Student[]>([]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { totalItemCount } = useCartContext();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    const SearchData = async () => {
      try {
        if (searchQuery.length >= 3) {
          const paramText = searchQuery;

          const seachSubject = await api.get(AdminApi.SEARCH_COUSES, {
            params: {
              text: paramText,
            },
          });

          setSearchCourse(seachSubject.data);
          const seachStudent = await api.get(AdminApi.SEARCH_STUDENT, {
            params: {
              text: paramText,
            },
          });
          setSearchStudent(seachStudent.data.items);
        } else {
          setSearchStudent([]);
          setSearchCourse([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    SearchData();
  }, [searchQuery]);

  return (
    <AppBar position="static" className="top_header">
      <Toolbar disableGutters className="top_header_inner">
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
          }}
        >
          Logged in as {GetUserRole()}
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
        <Typography
          variant="h5"
          noWrap
          component="a"
          href=""
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          LOGO
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

        <Box
          className="header-search"
          sx={{ mr: 2, display: "flex", flexDirection: "row-reverse" }}
        >
          <TextField
            placeholder="Search"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          {(searchStudent.length > 0 || searchCourse.length > 0) && (
            <Box className="search_result">
              {searchCourse.length > 0 && (
                <Box className="search_course_show">
                  <Typography
                    className="search_course_heding"
                    variant="h5"
                    gutterBottom
                  >
                    Subjects
                  </Typography>
                  {searchCourse.map((course) => (
                    <div key={course.id}>
                      <Link
                        to={`/admin/courses/view/${course.id}`}
                        onClick={() => setSearchQuery("")}
                      >
                        <span className="course_list">{course.name}</span>
                      </Link>
                    </div>
                  ))}
                </Box>
              )}
              {searchStudent.length > 0 && (
                <Box className="search_course_show">
                  <Typography
                    className="search_course_heding"
                    variant="h5"
                    gutterBottom
                  >
                    Students
                  </Typography>
                  {searchStudent.map((student) => (
                    <div key={student.id}>
                      <Link
                        to={`/admin/student/view/${student.id}`}
                        onClick={() => setSearchQuery("")}
                      >
                        <span className="course_list">{student.Name}</span>
                      </Link>
                    </div>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box className="loginUser" sx={{ flexGrow: 0 }}>
          <Grid container alignItems={"center"}>
            {isParentUser && (
              <Grid item className="cart_show">
                <Link to={totalItemCount === 0 ? "#" : "course-cart"}>
                  <Grid className="cartIconHeader" sx={{ mr: 2 }}>
                    <Box className="cartIconHeaderContainer">
                      <Typography
                        variant="body1"
                        className="cartIconHeaderConter"
                      >
                        {totalItemCount}
                      </Typography>
                      <img src={cartIcon} alt="cart" />
                    </Box>
                  </Grid>
                </Link>
              </Grid>
            )}
            <Grid item>
              <Avatar
                sx={{ width: 50, height: 50, mr: 2 }}
                alt={GetUserName()}
                src={`${REACT_APP_SERVER_BASE}${GetUserProfileImage()}`}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title={<span className="signOut_tooltip">{GetUserName()}</span>}
              >
                <Typography className="signOut" onClick={logout}>
                  <span className="userName">{GetUserName()}</span>
                  Sign Out
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
