export const AuthApi = {
    LOGIN: 'admin/auth/login',
    FORGET_PASSWORD: 'admin/auth/forget-password',
    FORGET_PASSWORD_TOKEN_VERIFY: 'admin/auth/forget-password/:token',
    CHANGE_PASSWORD: 'admin/auth/forget-password/:token',

    PARENTS_SIGNUP: 'parent/auth/registration',
    CREATE_PASSWORD_TOKEN_VERIFY: 'admin/auth/registration/:token',
    RESET_PASSWORD: 'admin/auth/registration/:token',
    UPDATE_USER_FIREBASE_UID: 'admin/auth/set-firebase_uid',
    
    PARENTS_SIGNIN: 'parent/auth/login',
    PARENTS_VERIFY_OTP: 'parent/auth/verify-otp',
    PARENTS_GENRATE_OTP: 'parent/auth/generate-opt',
    ADD_STUDENTS: 'student/auth/registration',
    ADD_TEACHER:'teacher/auth/registration',
}