import { lazy } from 'react';
import Layout from "../layout/admin/Layout";
// Lazy loading components for better performance
const Dashboard = lazy(() => import("../pages/admin/dashboard/Dashboard"));
const EditRole = lazy(() => import("../pages/admin/manage-roles/edit"));
const ViewRoles = lazy(() => import("../pages/admin/manage-roles/view"));
const ViewCourses = lazy(() => import("../pages/admin/manage-courses/view"));
const EditCourses = lazy(() => import("../pages/admin/manage-courses/editCourse/edit"));
const EditStudents = lazy(() => import("../pages/admin/parent-dashbard/manage-kids/add-kids/edit"));
const ViewQuiz = lazy(() => import("../pages/admin/manage-quiz/view"));
const EditQuiz = lazy(() => import("../pages/admin/manage-quiz/edit"));
const Setting = lazy(() => import("../pages/admin/settings/setting"));
const ProtectRoutes = lazy(() => import("../hooks/protectedRoutes/protectedRoutes").then(module => ({
  default: module.ProtectRoutes
})));
const HasPermission = lazy(() => import("../hooks/protectedRoutes/HasPermission").then(module => ({
  default: module.HasPermission
})));
const ViewForum = lazy(() => import("../pages/admin/manage-forums/view")) ;
const AddForum = lazy(() => import("../pages/admin/manage-forums/add")) ;
const ViewCategory = lazy(() => import("../pages/admin/manage-forums/categories/view")) ;
const VeiwDetail = lazy(() => import("../pages/admin/manage-courses/veiwdetail")) ;
const ViewThreads = lazy(() => import("../pages/admin/manage-forums/manage-threads/viewthreads")) ;
const ThreadViewDetail = lazy(() => import("../pages/admin/manage-forums/manage-threads/threadViewDetail")) ;
const ViewCourseCatalog = lazy(() => import("../pages/admin/course-catalog/view")) ;
const CourseDetail = lazy(() => import("../pages/admin/course-catalog/course-detail")) ;
const CourseCart = lazy(() => import("../pages/admin/manage-purchase-courses/CourseCart")) ;
const CourseEnrolled = lazy(() => import("../pages/admin/course-catalog/course-enrolled")) ;
const StudentAssignments = lazy(() => import("../pages/admin/student-dashboard/courses/student-assignments/Student-assignments")) ;
const StudentQuizes = lazy(() => import("../pages/admin/student-dashboard/courses/student-quizes/Studnet-quizes")) ;
const StudentCourses = lazy(() => import("../pages/admin/student-dashboard/courses/student-courses/studentCourses")) ;
const ViewTransactions = lazy(() => import("../pages/admin/transactions/view")) ;
const DetailTransactions = lazy(() => import("../pages/admin/transactions/details")) ;
const ViewEnrolledStudents = lazy(() => import("../pages/admin/enrolled-students/view")) ;
const ViewAdminSupport = lazy(() => import("../pages/admin/admin-support/view")) ;
const DetailsAdminSupport = lazy(() => import("../pages/admin/admin-support/details")) ;
const AssignCourseDetail = lazy(() => import("../pages/admin/student-dashboard/courses/student-coursedetail/course-detail")) ;
const EditAdminSupport = lazy(() => import("../pages/admin/admin-support/edit")) ;
const Messaging = lazy(() => import("../pages/admin/messaging/list")) ;
const ViewQuestionBank = lazy(() => import("../pages/admin/manage-question-bank/view")) ;
const EditQuestionBank = lazy(() => import("../pages/admin/manage-question-bank/edit")) ;
const ViewRefundRequests = lazy(() => import("../pages/admin/manage-refund-requests/view")) ;
const ViewTeacherCourses = lazy(() => import("../pages/admin/teacher-courses/view")) ;
const ViewTeacherStudents = lazy(() => import("../pages/admin/teacher-students/view")) ;
const KidsListView = lazy(() => import("../pages/admin/parent-dashbard/manage-kids/manage-kids-list/KidsListView")) ;
const KisdsDetails = lazy(() => import("../pages/admin/parent-dashbard/manage-kids/kids-details/view/view").then(module => ({
  default: module.KisdsDetails
})));
const StudentQuizesDetail = lazy(() => import("../pages/admin/student-dashboard/courses/student-quizes-detail/student-quizes-detail"));
const StudentQuizesDetailResult = lazy(() => import("../pages/admin/student-dashboard/courses/student-quizes-detail/student-quizes-result"));
const StudentQuizesDetailTable = lazy(() => import("../pages/admin/student-dashboard/courses/student-quizes-detail/student-quizes-final-table"));
const StudentAssignmentsDetail = lazy(() => import("../pages/admin/student-dashboard/courses/student-assignments/student-assignment-detail/student-assignment-detail"));
const TopStudentPanel = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/top-student/top-student"));
const ViewStudentPanel = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/top-student/student-view"));
const CoursePanel = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/courses/course-panel"));
const CourseViewPanel = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/courses/course-view"));
const QuizePanel = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/quize/quize-panel"));
const QuizeTable = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/quize/quize-table"));
const QuizeTableView = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/quize/quize-inner-table"));
const SettingPanel = lazy(() => import("../pages/admin/teacher-dashbaord/studnets/setting/setting-panel"));
const ViewParentsUser = lazy(() => import("../pages/admin/manage-users/parents/view"));
const EditParents = lazy(() => import("../pages/admin/manage-users/parents/edit"));
const ViewParentUsers = lazy(() => import("../pages/admin/manage-users/parents/view"));
const EditParent = lazy(() => import("../pages/admin/manage-users/parents/edit"));
const ViewTeacherUsers = lazy(() => import("../pages/admin/manage-users/teachers/view"));
const EditTeacher = lazy(() => import("../pages/admin/manage-users/teachers/edit"));
const ViewStudentUsers = lazy(() => import("../pages/admin/manage-users/students/view"));
const EditStudent = lazy(() => import("../pages/admin/manage-users/students/edit"));
const DetailsUser = lazy(() => import("../pages/admin/manage-users/students/details"));
const DetailsParent = lazy(() => import("../pages/admin/manage-users/parents/details"));
const ViewComplaints = lazy(() => import("../pages/admin/manage-complaints/view"));
const DetailsComplaints = lazy(() => import("../pages/admin/manage-complaints/details"));
const EditComplaints = lazy(() => import("../pages/admin/manage-complaints/edit"));
const DetailsTeacher = lazy(() => import("../pages/admin/manage-users/teachers/details"));
const EditStudentProfile = lazy(() => import("../pages/admin/manage-users-profile/students/edit"));
const EditParentProfile = lazy(() => import("../pages/admin/manage-users-profile/parents/edit"));
const EditTeacherProfile = lazy(() => import("../pages/admin/manage-users-profile/teachers/edit"));
const GetUserRole = lazy(() => import("../helper/helperFunction").then(module => ({
  default: module.GetUserRole
})));
const TeacherAssignments = lazy(() => import("../pages/admin/teacher-dashbaord/dashboard/assignments"));
const StudentQuizesAnswer = lazy(() => import("../pages/admin/student-dashboard/courses/student-quizes-detail/student-quiz-answers"));
const AssignmentEditor = lazy(() => import("../pages/admin/teacher-dashbaord/assignments/AssignmentEditor"));
const HomePageCms = lazy(() => import("../pages/admin/manage-pages/HomePage"));
const EditHomeSchooling = lazy(() => import("../pages/admin/manage-home-schooling/edit"));
const EditOurPrograms = lazy(() => import("../pages/admin/manage-our-programs/edit"));
const EditWhyStudy = lazy(() => import("../pages/admin/manage-why-study/edit"));
const FooterPageCms = lazy(() => import("../pages/admin/manage-pages/FrontendFooter"));
const PopupsCms = lazy(() => import("../pages/admin/manage-pages/Popups"));
const TermsAndConditionCms = lazy(() => import("../pages/admin/manage-pages/temsAndCondition"));
const ViewNoticeBoard = lazy(() => import("../pages/admin/manage-notice-board/view"));
const DetailsNoticeBoard = lazy(() => import("../pages/admin/manage-notice-board/details"));
const EditNoticeBoard = lazy(() => import("../pages/admin/manage-notice-board/edit"));
const AboutPageCms = lazy(() => import("../pages/admin/manage-pages/AboutPage"));
const FaqsPageCms = lazy(() => import("../pages/admin/manage-pages/FaqsPage"));
const ViewPurchasedCouses = lazy(() => import("../pages/admin/manage-purchased-course/view"));
const TheSuperCurriculumCms = lazy(() => import("../pages/admin/manage-pages/TheSuperCurriculumPage"));
const ParentFormPopup = lazy(() => import("../pages/admin/parent-form-popup"));
const IGCSE = lazy(() => import("../pages/admin/manage-pages/IGCSE"));
const SecondaryKS3 = lazy(() => import("../pages/admin/manage-pages/Secondary-KS3-page"));
const ViewSubjectAcces = lazy(() => import("../pages/admin/manage-assign-and-revoke/view"));
const QuizesDetail = lazy(() => import("../pages/admin/manage-courses/content/quizes-detail/quiz-answers"));
const StudentAssignmentsPDFEdit = lazy(() => import("../pages/admin/student-dashboard/courses/student-assignments/student-assignment-detail/student-assignment-pdf-edit"));
const GlobalCMSPopup = lazy(() => import("../pages/admin/parent-form-popup"));
const AssignmentsDetail = lazy(() => import("../pages/admin/manage-courses/Assignment/Assignment-view"));
const ViewaAchivedForum = lazy(() => import("../pages/admin/manage-forums/archivedforum"));
const AllCoursesReports = lazy(() => import("../pages/admin/manage-student-report-download/studentAllCoursesReport"));
const ViewInstallment = lazy(() => import("../pages/admin/manage-purchased-course/view-installment"));
const EmailsCms = lazy(() => import("../pages/admin/manage-pages/emails"));
const ConatactPageCms = lazy(() => import("../pages/admin/manage-pages/ContactPage"));


const commonRoutes = [
  {
    path: "",
    element: <Dashboard />,
  },
  {
    path: "thank-you",
    element: <Dashboard />,
  },
  {
    path: "add-student",
    element: <EditStudents />,
  },
  {
    path: "course-catalog",
    element: <ViewCourseCatalog />,
  },
  {
    path: "course-cart",
    element: <CourseCart />,
  },
  {
    path: "course-cart/view/:id",
    element: <CourseCart />,
  },

  {
    path: "course-catalog/view/:id",
    element: <CourseDetail />,
  },
  {
    path: "course-enrolled",
    element: <CourseEnrolled />,
  },

  // cms
  {
    element: <HasPermission permission="view.manage-CMS" />,
    children: [
      {
        path: "cms/home",
        element: <HomePageCms />,
      },
      {
        path: "cms/about",
        element: <AboutPageCms />,
      },
      {
        path: "cms/faqs",
        element: <FaqsPageCms />,
      },
      {
        path: "cms/footer",
        element: <FooterPageCms />,
      },
      {
        path: "cms/the-super-curriculum",
        element: <TheSuperCurriculumCms />,
      },
      {
        path: "cms/contact-us",
        element: <ConatactPageCms />,
      },
      {
        path: "cms/privacy-policy",
        element: (
          <GlobalCMSPopup
            type="privacy-policy"
            heading="Privacy Policy"
            successPathUrl="/admin/cms/privacy-policy"
          />
        ),
      },
      // {
      //   path: "cms/terms-conditions",
      //   element: (
      //     <GlobalCMSPopup
      //       type="terms-conditions"
      //       heading="Terms & Conditions"
      //       successPathUrl="/admin/cms/terms-conditions"
      //     />
      //   ),
      // },
      {
        path: "cms/IGCSE",
        element: <IGCSE />,
      },
      {
        path: "cms/Secondary-KS3",
        element: <SecondaryKS3 />,
      },
      {
        path: "cms/terms-conditions",
        element: <PopupsCms />,
      },
      {
        path: "cms/emails",
        element: <EmailsCms />,
      },
      // {
      //   path: "cms/termsandconditions",
      //   element: <TermsAndConditionCms />,
      // },
    ],
  },

  {
    element: <HasPermission permission="view.manage-courses" />,
    children: [
      {
        path: "courses",
        element: <ViewCourses />,
      },
      {
        path: "courses/view/:id?",
        element: <VeiwDetail />,
      },
      {
        path: "courses/quiz/view/:id?",
        element: <QuizesDetail />,
      },
      {
        path: "courses/assignment/view/:id",
        element: <AssignmentsDetail />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-courses" />,
    children: [
      {
        path: "courses/edit/:id?",
        element: <EditCourses />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-roles" />,
    children: [
      {
        path: "roles",
        element: <ViewRoles />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-roles" />,
    children: [
      {
        path: "roles/edit/:id?",
        element: <EditRole />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-quizes" />,
    children: [
      {
        path: "quiz",
        element: <ViewQuiz />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-quizes" />,
    children: [
      {
        path: "quiz/edit/:id?",
        element: <EditQuiz />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "forum",
        element: <ViewForum />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "achived-forum",
        element: <ViewaAchivedForum />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-forums" />,
    children: [
      {
        path: "forum/edit/:id?",
        element: <AddForum />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "forum/:forumSlug",
        element: <ViewThreads />,
      },
      {
        path: "forum/:forumSlug/:slug",
        element: <ThreadViewDetail />,
      },
    ],
  },

  {
    element: <HasPermission permission="view.manage-forums" />,
    children: [
      {
        path: "forum/categories/view",
        element: <ViewCategory />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.add-student" />,
    children: [
      {
        path: "add-student",
        element: <EditStudents />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.add-student" />,
    children: [
      {
        path: "add-student/edit/:id?",
        element: <EditStudents />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-settings" />,
    children: [
      {
        path: "setting",
        element: <Setting />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-transactions" />,
    children: [
      {
        path: "transactions",
        element: <ViewTransactions />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-transactions" />,
    children: [
      {
        path: "upcoming-installments",
        element: <ViewInstallment />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-transactions" />,
    children: [
      {
        path: "transactions/:id",
        element: <DetailTransactions />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-my-students-subjects" />,
    children: [
      {
        path: "student/subjects",
        element: <ViewEnrolledStudents />,
      },
    ],
  },

  {
    element: <HasPermission permission="view.manage-admin-support" />,
    children: [
      {
        path: "support",
        element: <ViewAdminSupport />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-admin-support" />,
    children: [
      {
        path: "support/:id",
        element: <DetailsAdminSupport />,
      },
    ],
  },
  {
    element: <HasPermission permission="create.manage-admin-support" />,
    children: [
      {
        path: "support/edit/:id?",
        element: <EditAdminSupport />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-complaints" />,
    children: [
      {
        path: "complaints",
        element: <ViewComplaints />,
      },
      {
        path: "complaint/:id",
        element: <DetailsComplaints />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-complaints" />,
    children: [
      {
        path: "complaint/edit/:id?",
        element: <EditComplaints />,
      },
    ],
  },

  {
    element: <HasPermission permission="view.manage-messages" />,
    children: [
      {
        path: "messages",
        element: <Messaging />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-question-bank" />,
    children: [
      {
        path: "question-bank",
        element: <ViewQuestionBank />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-question-bank" />,
    children: [
      {
        path: "question-bank/edit/:id?",
        element: <EditQuestionBank />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-refund-request" />,
    children: [
      {
        path: "refund-request",
        element: <ViewRefundRequests />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-notice-board" />,
    children: [
      {
        path: "notice-board",
        element: <ViewNoticeBoard />,
      },
      {
        path: "notice-board/edit/:id?",
        element: <EditNoticeBoard />,
      },
    ],
  },
];

const parent = [
  {
    element: <HasPermission permission="view.manage-kids" />,
    children: [
      {
        path: "student",
        element: <KidsListView />,
      },
      {
        path: "student/view/:id",
        element: <KisdsDetails />,
      },
      {
        path: "student-reports-all-courses/:id",
        element: <AllCoursesReports />,
      },
      {
        path: "student/view/:courseSlug/:id",
        element: <KisdsDetails />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-admin-support" />,
    children: [
      {
        path: "student/edit/:id?",
        element: <EditStudents />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-users" />,
    children: [
      {
        path: "user/parents",
        element: <ViewParentUsers />,
      },
      {
        path: "user/parents/view/:id",
        element: <DetailsParent />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-users" />,
    children: [
      {
        path: "user/parents/edit/:id",
        element: <EditParentProfile />,
      },
      {
        path: "user/parents/edit",
        element: <EditParent />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-users" />,
    children: [
      {
        path: "user/teachers",
        element: <ViewTeacherUsers />,
      },
      {
        path: "user/teachers/view/:id",
        element: <DetailsTeacher />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-users" />,
    children: [
      {
        path: "user/teachers/edit/:id",
        element: <EditTeacherProfile />,
      },
      {
        path: "user/teachers/edit",
        element: <EditTeacher />,
      },
    ],
  },
  {
    element: (
      <HasPermission
        permission={["view.manage-users", "view.manage-assign-students"]}
      />
    ),
    children: [
      {
        path: "user/student",
        element: <ViewStudentUsers />,
      },
      {
        path: "user/student/view/:id",
        element: <KisdsDetails />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-users" />,
    children: [
      {
        path: "user/student/edit/:id",
        element: <EditStudentProfile />,
      },
      {
        path: "user/student/add",
        element: <EditStudent />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-teacher-profile" />,
    children: [
      {
        path: "profile/teacher",
        element: <EditTeacherProfile />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-parent-profile" />,
    children: [
      {
        path: "profile/parent",
        element: <EditParentProfile />,
      },
    ],
  },
  {
    element: <HasPermission permission="edit.manage-student-profile" />,
    children: [
      {
        path: "profile/student",
        element: <EditStudentProfile />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-student-profile" />,
    children: [
      {
        path: "student/progress-report/:id?",
        element: <KisdsDetails />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-parent-courses" />,
    children: [
      {
        path: "purchased-courses",
        element: <ViewPurchasedCouses />,
      },
    ],
  },
  {
    element: <HasPermission permission="view.manage-assign-and-revoke" />,
    children: [
      {
        path: "subject-access",
        element: <ViewSubjectAcces />,
      },
    ],
  },
];

const student = [
  {
    element: <HasPermission permission="view.manage-student-courses" />,
    children: [
      {
        path: "student-courses",
        element: <StudentCourses />,
      },
      {
        path: "student-courses/view/:slug?",
        element: <AssignCourseDetail />,
      },

      {
        path: "student-quizes",
        element: <StudentQuizes />,
      },
      {
        path: "student-quizes/view/:id",
        element: <StudentQuizesDetail />,
      },
      {
        path: "student-quizes-answer/view/:id",
        element: <StudentQuizesAnswer />,
      },
      {
        path: "student-quizes/quiz-result/:id",
        element: <StudentQuizesDetailResult />,
      },
      {
        path: "student-quizes/student-quizes-detail-table/:id?",
        element: <StudentQuizesDetailTable />,
      },

      {
        path: "student-quizes",
        element: <StudentQuizes />,
      },
      {
        path: "student-assignments",
        element: <StudentAssignments />,
      },
      {
        path: "student-assignments/view/:id",
        element: <StudentAssignmentsDetail />,
      },
    ],
  },
];
const teacher = [
  {
    element: <HasPermission permission="view.manage-teacher-courses" />,
    children: [
      {
        path: "teacher-courses",
        element: <ViewTeacherCourses />,
      },
      {
        path: "teacher-courses/:slug",
        element: <VeiwDetail />,
      },
    ],
  },
  // Teacher Dashboard
  {
    path: "student-assignments/edit-pdf/:id",
    element: <StudentAssignmentsPDFEdit />,
  },
  // Top Student
  {
    path: "top-student",
    element: <TopStudentPanel />,
  },
  {
    path: "assignments/view/:id",
    element: <StudentAssignmentsDetail />,
  },
  {
    path: "assignments/view/:id/editor",
    element: <AssignmentEditor />,
  },
  {
    path: "students/assignments",
    element: <TeacherAssignments />,
  },
  {
    path: "view-student",
    element: <ViewStudentPanel />,
  },

  // Courses panel
  {
    path: "courses-panel",
    element: <CoursePanel />,
  },
  {
    path: "courses-view",
    element: <CourseViewPanel />,
  },

  // Quiz panel
  {
    path: "quize-panel",
    element: (
      <QuizePanel
        onFiltersChange={function (filters: {
          teacherId: any;
          status: string | null;
          searchQuery: string | null;
        }): void {
          throw new Error("Function not implemented.");
        }}
      />
    ),
  },
  {
    path: "quize-table",
    element: <QuizeTable />,
  },
  {
    path: "quize-table-view",
    element: <QuizeTableView />,
  },
];

const allRoutes = [
  {
    path: "/admin",
    element: <Layout />,
    pathName: "Dashboard",
    children: [...commonRoutes, ...student, ...parent, ...teacher],
  },
];

export const AdminRoutes = {
  element: <ProtectRoutes />,
  children: allRoutes,
};
