import { CartProvider } from "../context/CartContext";
import { QuizResProvider } from "../context/QuizResponseContext";
import { StudentIdProvider } from "../context/getStudentId";
import { AuthProvider } from "./auth/auth";

interface ProviderProps {
  children: any;
}

const AppProvider = function (props: ProviderProps) {
  return (
    <>
      <AuthProvider>
        <CartProvider>
          <QuizResProvider>
            <StudentIdProvider>{props.children}</StudentIdProvider>
          </QuizResProvider>
        </CartProvider>
      </AuthProvider>
    </>
  );
};

export default AppProvider;
