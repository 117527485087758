import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.scss";

const FooterLinks: React.FC = () => {
  return (
    <div className={styles.footer_links}>
      {/* <div className='footer_links'> */}
      <ul>
        <li>
          <Link to="/terms-conditions">Terms</Link>
        </li>
        <li>
          <Link to="/privacy-policy">
            {" "}
            <span> | </span> Privacy Policy
          </Link>
        </li>
        {/* <li>
          <Link to="/#Site Map">Site Map</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default FooterLinks;
