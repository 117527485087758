export const API_KEY = process.env.REACT_APP_API_BASE;
export const SERVER_KEY = process.env.REACT_APP_SERVER_BASE;
export const FIREBASE_APIKEY = process.env.REACT_APP_FIREBASE_APIKEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_ID = process.env.REACT_APP_FIREBASE_MESSAGING_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_VAPID_ID = process.env.REACT_APP_FIREBASE_VAPID_ID;
export const REACT_APP_ENV = process.env.REACT_APP_ENV;