import React, { createContext, useContext, ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { GetUserId, GetUserRole } from "../helper/helperFunction";

// Define the type for the context object
interface StudentIdContextType {
  studentId: string | null;
  setNewId: (newId: string) => void;
}

// Create a context with the defined type
const StudentIdContext = createContext<StudentIdContextType>({
  studentId: null,
  setNewId: () => {},
});

// Define the props for the provider component
interface ProviderProps {
  children: ReactNode;
}

// Create the provider component
export const StudentIdProvider: React.FC<ProviderProps> = ({ children }) => {
  const User = GetUserRole() === "Student";
  const studentId = GetUserId();

  // Use useState to manage  the studentId
  const [id, setId] = useState<string | null>(null);

  // Check if studentIdFromParams is provided, if yes, use it, otherwise use studentId
  const ID = User ? studentId : id || null;

  // Function to set a new student ID
  const setNewId = (newId: string) => {
    setId(newId);
  };

  return (
    <StudentIdContext.Provider value={{ studentId: ID, setNewId }}>
      {children}
    </StudentIdContext.Provider>
  );
};

// Create a hook to consume the context
export const useStudentId = () => {
  const context = useContext(StudentIdContext);
  return context;
};
