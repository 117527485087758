export const FrontendApi = {
   HOME_PAGE_CMS:"helper/homePage",
   FRONTEND_FOOTER:"helper/homePage-footer",
   FRONTEND_CONTACT_FORM:"helper/home-page/contact",
   ABOUT_PAGE_CMS:"helper/aboutPage",
   CONTACT_PAGE_CMS:"helper/contact-page-info",
   FAQ_PAGE_CMS_BANNER:"helper/faq-page",
   FAQS_DATA:"helper/faq-page/contents-by-category",
   FAQS_CATLIST:"helper/faq-page/categories",
   META_TAGS_VIEW:"admin/meta-tags/meta/:type",
   CURRICULUM_PAGE:"helper/curriculum-page",
   IGCSE_PAGE:"helper/cms-courses-page/:type"
}