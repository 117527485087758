import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HasPermissionMenu } from "../../../helper/helperFunction";
import { useAuth } from "../../../hooks/auth/auth";
import { getMenuItems } from "./menu-item";

interface MenuItem {
  permission: string;
  className: string;
  icon: string;
  label: string;
  subMenu?: SubMenuItem[];
  path: string;
}

interface SubMenuItem {
  permission: string;
  label: string;
  path: string;
}

const Menus = () => {
  const { state } = useAuth();
  const authState = state ?? { userRole: "" };
  const menuItem: any = getMenuItems(authState);

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>(location.pathname);
  const [openMenus, setOpenMenus] = useState<number[]>([]);

  const toggleSubMenu = (menuIndex: number) => {
    if (openMenus.includes(menuIndex)) {
      setOpenMenus(openMenus.filter((index) => index !== menuIndex));
    } else {
      setOpenMenus([...openMenus, menuIndex]);
    }
  };

  const handleMenuClick = (path: string, label: string) => {
    setCurrentPath(path);
    document.title = label; // Update the page title
  };

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <Box className="sidebar-menu">
        <ul className="menu-wrap">
          {menuItem &&
            menuItem.map((menuItem: any, index: any) => (
              <HasPermissionMenu
                key={index}
                permission={menuItem.permission}
                notForAdmin={menuItem.notForAdmin}
                notForParent={menuItem.notForParent}
              >
                {menuItem.subMenu ? (
                  <li
                    key={index}
                    className={`${menuItem.className} ${menuItem.icon} ${
                      openMenus.includes(index) ? "active" : ""
                    }`}
                  >
                    <span
                      key={index}
                      className={`users-item ${menuItem.icon} ${
                        openMenus.includes(index) ? "active" : ""
                      }`}
                      onClick={() => toggleSubMenu(index)}
                    >
                      <Link
                        onClick={() =>
                          handleMenuClick(menuItem.path, menuItem.label)
                        }
                        to={"#"}
                      >
                        {menuItem.label}
                      </Link>
                    </span>
                    {openMenus.includes(index) && (
                      <ul className="sub-menu">
                        {menuItem.subMenu.map(
                          (subMenuItem: any, subIndex: any) => (
                            <HasPermissionMenu
                              key={subIndex}
                              permission={subMenuItem.permission}
                            >
                              <li key={subIndex}>
                                <Link
                                  onClick={() =>
                                    handleMenuClick(
                                      menuItem.path,
                                      menuItem.label
                                    )
                                  }
                                  to={subMenuItem.path}
                                >
                                  {subMenuItem.label}
                                </Link>
                              </li>
                            </HasPermissionMenu>
                          )
                        )}
                      </ul>
                    )}
                  </li>
                ) : (
                  <li
                    key={index}
                    className={`${menuItem.icon} ${menuItem.className} ${
                      currentPath === menuItem.path ? "active" : ""
                    }`}
                  >
                    <Link
                      onClick={() =>
                        handleMenuClick(menuItem.path, menuItem.label)
                      }
                      to={menuItem.path}
                    >
                      {menuItem.label}
                    </Link>
                  </li>
                )}
              </HasPermissionMenu>
            ))}
        </ul>
      </Box>
    </>
  );
};

export default Menus;
