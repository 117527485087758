import Header from "./partials/Header";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import "../../static/css/style.scss";
import LogoWhite from "../../static/images/logo-white.svg";
import ToggleIcon from "../../static/images/toggle-icon.svg";
import Menu from "./partials/Menu";
import { Link, useLocation, useOutlet } from "react-router-dom";
import Footer from "./partials/footer/Footer";
import OutletTransition from "../../components/outletTransitions/OutletTransitions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessageListener } from "../../services/firebase";
import { checkLogoutTime } from "../../helper/helperFunction";
import { useAuth } from "../../hooks/auth/auth";

const drawerWidth: number = 305;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  background: "linear-gradient(180deg, #071836 0%, #273A5C 35.58%)",
}));

export default function Layout() {
  const [notification, setNotification] = React.useState({
    title: "",
    body: "",
  });
  const [showNotification, setShowNotification] = React.useState(false);

  onMessageListener()
    .then((payload: any) => {
      if (payload && payload.notification) {
        setShowNotification(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
      }
    })
    .catch((err) => console.error("failed: ", err));

  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (showNotification) {
      toast.success(notification.body, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [notification, showNotification]);

  const currentOutlet = useOutlet();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const location = useLocation();

  const { dispatch } = useAuth();

  React.useEffect(() => {
    checkLogoutTime(dispatch);
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer
        className="side-bar"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="main-logo">
          <Link to="/" target="_blank">
          <Box component="img" alt="Logo" src={LogoWhite} />
        </Link>
        </DrawerHeader>
        <Menu />
      </Drawer>
      <Main
        open={open}
        className={`main-content dashboard-main ${
          !open ? "dashboard_main_close" : ""
        }`}
      >
        <AppBar position="fixed" open={open} className="main-header">
          <Box
            className="togglebutton"
            component="img"
            onClick={handleDrawerToggle}
            alt="Logo"
            src={ToggleIcon}
          />
          <Box
            className="togglebutton"
            component="img"
            onClick={handleDrawerToggle}
            alt="Logo"
            src={ToggleIcon}
          />
          <Header />
        </AppBar>
        <DrawerHeader className="main_spacer" />
        <ToastContainer />
        <OutletTransition outlet={currentOutlet} />
        <Box className="dashboard-footer">
          <Footer />
        </Box>
      </Main>
    </Box>
  );
}
