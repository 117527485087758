import React, { ReactNode, createContext, useContext, useState } from "react";

interface QuizResProviderProps {
  children: ReactNode;
}

interface QuizContent {
  id: number;
  question: string;
  answers: string;
  answer_key: string;
  type: 'multiple-choices';
  createdAt: string;
  updatedAt: string;
}

interface QuizResponse {
  studentQuizId: number;
  quiz_id: number;
  quizContent: QuizContent;
}

interface QuizResContextValue {
  quizRes: QuizResponse | undefined;
  setQuizRes: React.Dispatch<React.SetStateAction<QuizResponse | undefined>>;
  duration: number | null | undefined;
  completion_duration:any;
  setDuration: React.Dispatch<React.SetStateAction<number | null | undefined>>;
  setCompletion_duration:any;
}

export const QuizResContext = createContext<QuizResContextValue>({
  quizRes: undefined,
  setQuizRes: () => {},
  duration: null,
  completion_duration: null,
  setDuration: () => {},
  setCompletion_duration: () => {},
});

export const useQuizResContext = () => {
  const context = useContext(QuizResContext);
  if (!context) {
    throw new Error("useQuizResContext must be used within a QuizResProvider");
  }
  return context;
};

export const QuizResProvider = function(props: QuizResProviderProps) {
  const [quizRes, setQuizRes] = useState<QuizResponse | undefined>(undefined);
  const [duration, setDuration] = useState<number | null |undefined>(); 
  const [completion_duration, setCompletion_duration] = useState<number | null |undefined>(); 

  return (
    <QuizResContext.Provider value={{ quizRes, setQuizRes, duration, setDuration, completion_duration, setCompletion_duration }}>
      {props.children}
    </QuizResContext.Provider>
  );
};
